<template>
  <!-- fullBg -->
  <div class="all-page" id='allPageVer2' :style="{backgroundImage: `url(${img.customized.ver2.fullBg})`}">
    <div class="made-page" ref="fullscreenElement">
      <div class="made-head">
        <headPage />
      </div>
      <div class="made-box">
        <div class="made-left">
          <div class="settingPage">
            <settingPage></settingPage>
          </div>
          <div class="countPage">
            <countPage></countPage>
          </div>
        </div>
        <div class="made-gis">
          <gisPage />
        </div>
        <div class="made-right">
          <div class="made-right-box">
            <allTotalPage></allTotalPage>
          </div>
          <div class="made-right-box">
            <contrastPage></contrastPage>
          </div>
          <div class="made-right-box">
            <sortBox1Page></sortBox1Page>
          </div>
        </div>
      </div>
      <!-- <div class="made-box">
        <div class="made-left">
          <settingPage class="left-box" /> 
          <device class="left-box" /> 
        </div>
        <div class="made-gis">
          <gisPage />
        </div>
        <div class="made-right">
          <energyPage />
        </div>             
      </div> -->
    </div>
  </div>
</template>
<script>

import headPage from './head/Index'
// import autofit from "autofit.js"
import settingPage from './left/setting'
import countPage from './left/count'
import allTotalPage from './right/allTotal'
import contrastPage from './right/contrast'
import sortBox1Page from './right/sortBox1'
// import device from './left/device'
import gisPage from './gis/index'
export default {
  name: 'customizedHome',
  components: {
    headPage,
    settingPage,
    countPage,
    // device,
    allTotalPage,
    sortBox1Page,
    contrastPage,
    gisPage,
  },
  props: {
  },
  data() {
    return {
      projectId: 0,
      menus: [],
    }
  },
  mounted: function () {
    // this.enterFullscreen();
    // autofit.init({
    //   dw: 3072,
    //   dh: 1576,
    //   el: "#allPageVer2",
    //   resize: true
    // }, false)
    // setTimeout(() => {
    //   this.enterFullscreen();
    // }, 200);
  },
  methods: {
    enterFullscreen() {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) { // Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
        document.documentElement.msRequestFullscreen();
      }
    },
  }
}
</script>
<style scoped>
.all-page {
  width: 100%;
  height: 100%;
  background-color: rgba(6, 13, 24, 1);
}
.made-page {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.made-head {
  width: 100%;
  height: 88px;
}
.made-box {
  width: calc(100%);
  height: calc(100% - 48px);
  padding: 24px 30px;
  display: flex;
  justify-content: space-between;
}
.made-left,
.made-right {
  display: flex;
  width: calc(100% / 7 * 2);
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 40;
  position: relative;
}
.settingPage {
  width: 100%;
  height: 66.6%;
  z-index: 20;
}
.countPage {
  width: 100%;
  height: 33.3%;
  z-index: 20;
}
.made-gis {
  width: calc(100% / 7 * 3);
  height: 100%;
}
.made-right-box {
  width: 100%;
  height: calc(100% / 3);
  z-index: 20;
}
</style>
