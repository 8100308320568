<template>
  <div class="gisPage">
    <div class="gis-map">
      <!-- <mapPage /> -->
    </div>
    <div class="gis-stat">
      <div class='gis-module'>
        <installPage></installPage>
      </div>
      <div class='gis-module'>
        <sortBox2Page></sortBox2Page>
      </div>
    </div>
    <div class="dis-total">
      <totalPage />
    </div>
  </div>
</template>
<script>

// import mapPage from './map'
import sortBox2Page from './sortBox2'
import installPage from './install'
import totalPage from './total'
export default {
  name: 'gisPage',
  components: {
    // mapPage,
    sortBox2Page,
    installPage,
    totalPage,
  },
  props: {
  },
  data() {
    return {

    }
  },
  mounted: function () {

  },
  methods: {

  }
}
</script>
<style scoped>
.gisPage {
  width: 100%;
  height: 100%;
  position: relative;
}
.dis-total {
  position: absolute;
  z-index: 20;
  left: 30px;
  top: 30px;
}

.gis-map {
  width: calc(100%);
  height: 75%;
}
.gis-stat {
  width: calc(100%);
  height: 25%;
  display: flex;
  justify-content: space-between;
}
.gis-module {
  width: calc(50% - 10px);
  height: 100%;
}
</style>
