<template>
  <div class="contrastPage">
    <titlePage :option='title'></titlePage>
    <div class="allTotal-box">
      <div class="total-box">
        <div class="total-list">
          <i class="horn horn1"></i>
          <i class="horn horn2"></i>
          <i class="horn horn3"></i>
          <i class="horn horn4"></i>
          <div class="total-list-num textSiz">
            <!-- {{showData.currentMonth}} -->
            <count-up :end-val="showData.currentMonth*1" :start-val="0" :key="countIndex"></count-up>
          </div>
          <div class="total-list-unit">当月能耗(KWh)</div>
        </div>
        <div class="total-list">
          <i class="horn horn1"></i>
          <i class="horn horn2"></i>
          <i class="horn horn3"></i>
          <i class="horn horn4"></i>
          <div class="total-list-num textSiz">
            <count-up :end-val="showData.lastMonth*1" :start-val="0" :key="countIndex"></count-up>
          </div>
          <div class="total-list-unit">上月能耗(KWh)</div>
        </div>
        <div class="total-list">
          <i class="horn horn1"></i>
          <i class="horn horn2"></i>
          <i class="horn horn3"></i>
          <i class="horn horn4"></i>
          <div class="total-list-num textSiz" style="color:#F8415F">{{showData.sameRate}}%</div>
          <div class="total-list-unit">同比</div>
        </div>
        <div class="total-list">
          <i class="horn horn1"></i>
          <i class="horn horn2"></i>
          <i class="horn horn3"></i>
          <i class="horn horn4"></i>
          <div class="total-list-num textSiz" style="color:#3CFACB">{{showData.lastRate}}%</div>
          <div class="total-list-unit">环比</div>
        </div>
      </div>
      <div class="total-line">
        <div class="total-line-title">
          <div class="line-title-list">
            <i style="background:#57D8FD"></i>
            <span>去年</span>
          </div>
          <div class="line-title-list">
            <i style="background:#498FFD"></i>
            <span>今年</span>
          </div>
          <div class="line-title-list">
            <i style="background:#C775DE"></i>
            <span>同比</span>
          </div>
          <div class="line-title-list">
            <i style="background:#DEB475"></i>
            <span>环比</span>
          </div>
        </div>
        <div class='total-line-box'>
          <!-- <chartsLine :option="lightLine" id="contrastPage1" :key="timer"></chartsLine> -->
          <div class="echarts-contrastPage" id="contrastPage"> </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titlePage from '../pubilc/title'
import * as echarts from 'echarts/core';
import { LegendComponent } from 'echarts/components';
import CountUp from 'vue-countup-v2';
import { mapState } from 'vuex'
echarts.use([LegendComponent]);
export default {
  name: 'contrastPage',
  components: {
    titlePage,
    CountUp
  },
  props: {

  },
  data() {
    return {
      timer: 0,
      allDateIndex: 0,
      title: { zh: '月能耗同环比统计', en: 'MONTHLY ENERGY CONSUMPTION COMPARISON' },
      showData: {
        currentMonth: 95792.99,
        lastMonth: 175493.25,
        lastRate: -45.42,
        lastYear: 7068.57,
        sameRate: 1255.2
      },
      data1: [],
      data2: [],
      lightLine: {
        title: '',
        isAnimation: true,
        x: [],
        unit: '',
        yBorderColor: 'rgba(34, 51, 83, 0.45)',
        series: [
          {
            name: '每日能耗(KWh)',
            data: []
          },
          {
            name: '节约能耗(KWh)',
            data: []
          },
          {
            name: '改造前功率(KW)',
            data: []
          }
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {} //下阴影
        }
      },
      countIndex: 0,
      stopTime: null,
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
  },
  watch: {
    windowOnResize() {
      if (this.barchart) {
        this.barchart.resize();
      }
    },
  },
  mounted: function () {
    this.QueryMonthEnergy();
    this.stopTime = setInterval(() => {
      this.countIndex++
    }, 8000);
  },
  methods: {
    QueryMonthEnergy() {
      this.$axios.post(`visual/home/QueryMonthEnergy`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'showData', res.data);

        }
      })
      this.$axios.post(`visual/home/QueryMonthEnergyList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'data2', res.data);
          // current: 3024833.2
          // last: 0
          // lastRate: 100
          // month: 1
          // sameRate: 100;
          this.getbarchart();
        }
      })
    },
    getbarchart() {
      let barchartDom = document.getElementById("contrastPage");
      this.barchart = echarts.init(barchartDom);
      let option = {
        color: ["#3398DB"],
        xAxis: {
          data: [],
          axisLabel: {
            color: "#8CBAEE",
          },
          axisLine: {
            lineStyle: {
              color: "#8CBAEE"
            }
          },
          axisTick: {
            show: false
          }
        },
        grid: {
          top: "10%", // 等价于 y: '16%'
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        yAxis: [
          {
            scale: true,//脱离0值比例
            axisLabel: {
              color: "#8CBAEE"
            },
            axisLine: {
              lineStyle: {
                color: "#8CBAEE"
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: "#0B0842"
              }
            }
          },
          {
            type: 'value',
            name: '',
            axisLabel: {
              formatter: '{value}%'
            }
          }
        ],
        // 立方体柱状
        series: [
          {
            type: "custom",
            data: [],
            renderItem: (params, api) => {
              const basicsCoord = api.coord([api.value(0), api.value(1)]);
              const topBasicsYAxis = basicsCoord[1];
              const basicsXAxis = basicsCoord[0] + 5;
              const bottomYAxis = api.coord([api.value(0), 0])[1];
              return {
                type: "group",
                children: [
                  // 左侧
                  {
                    type: "polygon",
                    shape: {
                      points: [
                        [basicsXAxis - 6, topBasicsYAxis - 4],
                        [basicsXAxis - 6, bottomYAxis],
                        [basicsXAxis, bottomYAxis],
                        [basicsXAxis, topBasicsYAxis]
                      ]
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#009AC7' },
                        { offset: 1, color: '#009AC7' }
                      ])
                    }
                  },
                  // 右侧
                  {
                    type: "polygon",
                    shape: {
                      points: [
                        [basicsXAxis, topBasicsYAxis],
                        [basicsXAxis, bottomYAxis],
                        [basicsXAxis + 10, bottomYAxis],
                        [basicsXAxis + 10, topBasicsYAxis - 6]
                      ]
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#71DFFF' },
                        { offset: 0.5, color: '#71DFFF' },
                        { offset: 1, color: '#66C8E6' }
                      ])
                    }
                  },
                  // 顶部
                  {
                    type: "polygon",
                    shape: {
                      points: [
                        [basicsXAxis, topBasicsYAxis],
                        [basicsXAxis - 6, topBasicsYAxis - 4],
                        [basicsXAxis + 4, topBasicsYAxis - 10],
                        [basicsXAxis + 10, topBasicsYAxis - 6]
                      ]
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#B2EEFF' },
                        { offset: 1, color: '#B2EEFF' }
                      ])
                    }
                  }
                ]
              };
            }
          },
          {
            type: "custom",
            data: [],
            renderItem: (params, api) => {
              const basicsCoord = api.coord([api.value(0), api.value(1)]);
              const topBasicsYAxis = basicsCoord[1];
              const basicsXAxis = basicsCoord[0] - 15;
              const bottomYAxis = api.coord([api.value(0), 0])[1];
              return {
                type: "group",
                children: [
                  // 左侧
                  {
                    type: "polygon",
                    shape: {
                      points: [
                        [basicsXAxis - 6, topBasicsYAxis - 4],
                        [basicsXAxis - 6, bottomYAxis],
                        [basicsXAxis, bottomYAxis],
                        [basicsXAxis, topBasicsYAxis]
                      ]
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#1169E0' },
                        { offset: 1, color: '#387BFE' }
                      ])
                    }
                  },
                  // 右侧
                  {
                    type: "polygon",
                    shape: {
                      points: [
                        [basicsXAxis, topBasicsYAxis],
                        [basicsXAxis, bottomYAxis],
                        [basicsXAxis + 10, bottomYAxis],
                        [basicsXAxis + 10, topBasicsYAxis - 6]
                      ]
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#387BFE' },
                        { offset: 1, color: '#7CB1FD' }
                      ])
                    }
                  },
                  // 顶部
                  {
                    type: "polygon",
                    shape: {
                      points: [
                        [basicsXAxis, topBasicsYAxis],
                        [basicsXAxis - 6, topBasicsYAxis - 4],
                        [basicsXAxis + 4, topBasicsYAxis - 10],
                        [basicsXAxis + 10, topBasicsYAxis - 6]
                      ]
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#387BFE' },
                        { offset: 1, color: '#7CB1FD' }
                      ])
                    }
                  }
                ]
              };
            }
          },
          {
            name: '',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + '%';
              }
            },
            smooth: true,
            data: [],
            symbol: 'none',
            lineStyle: {
              color: '#C775DE'
            }
          },
          {
            name: '',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + '%';
              }
            },
            smooth: true,
            data: [],
            symbol: 'none',
            lineStyle: {
              color: '#DEB475'
            }
          }
        ]
      };
      // this.$set(this, 'data2', res.data);
      // current: 3024833.2
      // last: 0
      // lastRate: 100
      // month: 1
      // sameRate: 100;
      option.xAxis.data = [];
      option.series[0].data = [];
      option.series[1].data = [];
      option.series[2].data = [];
      option.series[3].data = [];

      this.data2.forEach(el => {
        option.xAxis.data.push(el.month);
        option.series[0].data.push(el.last)
        option.series[1].data.push(el.current)
        option.series[2].data.push(el.sameRate)
        option.series[3].data.push(el.lastRate)
      });
      option && this.barchart.setOption(option);
    },
  },
  beforeDestroy() {
    clearTimeout(this.stopTime);
  }
}
</script>
<style scoped>
.contrastPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.allTotal-box {
  width: 100%;
  padding-left: 22px;
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
}

.total-box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.total-list {
  width: calc((100% - 45px) / 4);
  height: 70px;
  background: linear-gradient(
    0deg,
    rgba(13, 88, 131, 0.6),
    rgba(8, 18, 34, 0.6)
  );
  /* opacity: 0.6; */
  position: relative;
  text-align: center;
  align-content: center;
}
.horn {
  width: 6px;
  height: 6px;
  border: 3px solid #6dafe2;
  position: absolute;
}
.horn1 {
  border-bottom: none;
  border-right: none;
  top: 0;
  left: 0;
}
.horn2 {
  border-bottom: none;
  border-left: none;
  top: 0;
  right: 0;
}
.horn3 {
  border-top: none;
  border-right: none;
  bottom: 0;
  left: 0;
}
.horn4 {
  border-top: none;
  border-left: none;
  bottom: 0;
  right: 0;
}
.total-list-num {
  font-family: Bahnschrift;
  font-weight: 400;
  font-size: 22px;
  color: #ffffff;
  line-height: 24px;
}
.total-list-unit {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #9ab9d7;
}
.total-line {
  flex: 1 1 200px;
}
.total-line-box {
  width: 100%;
  height: calc(100% - 40px);
  margin-bottom: 20px;
}
.echarts-contrastPage {
  width: 100%;
  height: 100%;
}
.total-line-title {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: center;
}
.line-title-list i {
  width: 8px;
  height: 8px;
  background: #4f9dfc;
  display: inline-block;
  margin: 0 5px 0 15px;
}
.line-title-list span {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #7c92af;
}
.textSiz {
  display: inline-block;
  animation: jump 2s 3;
}

@keyframes jump {
  20% {
    -webkit-transform: rotate(5deg);
  }

  40% {
    -webkit-transform: rotate(-5deg);
  }

  60% {
    -webkit-transform: rotate(2deg);
  }

  80% {
    -webkit-transform: rotate(-2deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}
</style>
