<template>
  <div class="totalPage">
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon1" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">项目总数(个)</div>
        <div class="stat-box-num">
          {{statData.projectCount}}
        </div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon2" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">配电箱总数(个)</div>
        <div class="stat-box-num">
          <count-up :end-val="conversion(statData.powerboxCount)" :start-val="0" :key="countIndex"></count-up>{{statData.powerboxCount>100000?'万':''}}</div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon3" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">单灯总数(个)</div>
        <div class="stat-box-num">
          <count-up :end-val="conversion(statData.lightCount)" :start-val="0" :key="countIndex"></count-up>{{statData.lightCount>100000?'万':''}}</div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon4" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">能耗总数(KW·h)</div>
        <div class="stat-box-num">
          <count-up :end-val="conversion(statData.energyTotal)" :start-val="0" :key="countIndex"></count-up>{{statData.energyTotal>100000?'万':''}}</div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon5" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">节能总数(KW·h)</div>
        <div class="stat-box-num">
          <count-up :end-val="conversion(statData.energyConservation)" :start-val="0" :key="countIndex"></count-up>{{statData.energyConservation>100000?'万':''}}</div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon6" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">减碳总数(千克)</div>
        <div class="stat-box-num">
          <count-up :end-val="conversion(statData.carbonReduction)" :start-val="0" :key="countIndex"></count-up>{{statData.carbonReduction>100000?'万':''}}</div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon7" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">减少砍伐树木(棵)</div>
        <div class="stat-box-num">
          <count-up :end-val="conversion(statData.treeReduction)" :start-val="0" :key="countIndex"></count-up>{{statData.treeReduction>100000?'万':''}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import CountUp from 'vue-countup-v2';
export default {
  name: 'totalPage',
  components: {
    CountUp
  },
  props: {
  },
  data() {
    return {
      statData: {
        carbonReduction: 194556440.59,
        energyConservation: 349418894.74,
        energyTotal: 34419595.26,
        lightCount: 29228,
        powerboxCount: 1613,
        projectCount: 410,
        treeReduction: 17186965,
      },
      countIndex:0,
      stopTime: null,
    }
  },
  mounted: function () {
    this.getStat()
    this.stopTime = setInterval(() => {
      this.countIndex++
    }, 7000);
  },
  methods: {
    getStat() {
      this.$axios.post(`visual/home/QueryDeviceAndEnergy`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'statData', res.data);
        }
      })
    },
    conversion(num) {
      if (num < 100000) {
        return num*1;
      }
      let digit = ''
      digit = (num / 10000).toFixed(2) 
      return digit*1;
    }
  },
  beforeDestroy() {
    clearTimeout(this.stopTime);
  }
}
</script>
<style scoped>
.totalPage {
  display: flex;
  flex-direction: column;
}
.stat-box-img {
  width: 56px;
  height: 68px;
}
.stat-box {
  display: flex;
  margin-bottom: 30px;
}
.stat-box-total {
  margin-left: 10px;
  align-content: center;
}
.stat-box-name {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #9fc4de;
}
.stat-box-num {
  font-family: Bahnschrift;
  font-weight: 400;
  font-size: 26px;
  color: #f6fafd;
  text-shadow: 0px 0px 18px #023657;
  background: -webkit-linear-gradient(
    -90deg,
    #fef9f2 16.6748046875%,
    #f6bc69 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

</style>
